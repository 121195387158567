<template>
  <div v-if="standard">
    <div class="standard-wrap">
      <!-- <div class="product-pic">
        <img :src="standard.classPic" alt="" />
        <video src="https://vd4.bdstatic.com/mda-jjjghsd929mh6s62/hd/mda-jjjghsd929mh6s62.mp4?v_from_s=nj_videoui_4135&auth_key=1612251097-0-0-c40f96659091e06d4b4d44b9b2e3d99c&bcevod_channel=searchbox_feed&pd=1&pt=3&abtest=8_1" autoplay></video>
      </div> -->
      <div class="product-info">
        <div class="product-name" v-html="standard.allName">
        </div>
      </div>
      <div class="product-detail">
        <div class="title">标准详情</div>
        <div class="content">
          <table border="1" cellspacing="0" cellpadding="0.267rem">
            <thead>
              <tr>
                <th colspan="2">基本信息</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="name">产品编号</td>
                <td>{{ standard.chinaStdType }}</td>
              </tr>
              <tr>
                <td class="name">标准名称</td>
                <td v-html="standard.allName"></td>
              </tr>

              <tr v-if="standard.intro">
                <td class="name">简介</td>
                <td>
                  {{ standard.intro }}
                </td>
              </tr>

              <tr v-if="standard.standardState">
                <td class="name">标准状态</td>
                <template v-if="standard.standardState">
                  <td v-if="standard.standardState == 1">已经废止</td>
                  <td v-else-if="standard.standardState == 2">现行有效</td>
                  <td v-else-if="standard.standardState == 3">部分有效</td>
                  <td v-else-if="standard.standardState == 4">未知</td>
                  <td v-else-if="standard.standardState == 5">即将废止</td>
                  <td v-else-if="standard.standardState == 6">即将实施</td>
                  <td v-else>未知</td>
                </template>
              </tr>

              <tr v-if="standard.publishDate">
                <td class="name">发布日期</td>
                <td>{{ standard.publishDate }}</td>
              </tr>

              <tr v-if="standard.implDate">
                <td class="name">实施日期</td>
                <td>{{ standard.implDate }}</td>
              </tr>

              <tr v-if="standard.issueDate">
                <td class="name">生效日期</td>
                <td>{{ standard.issueDate }}</td>
              </tr>

              <tr v-if="standard.tags">
                <td class="name">标准类型</td>
                <td>{{ standard.tags }}</td>
              </tr>

              <tr v-if="standard.industryName">
                <td class="name">行业分类</td>
                <td>{{ standard.industryName}}</td>
              </tr>

              <tr v-if="standard.issueDept">
                <td class="name">颁发部门</td>
                <td>{{ standard.issueDept }}</td>
              </tr>
              <tr>
                <td class="name">声明</td>
                <td>资源来源于网络，如有疑问可联系删除</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="recommend-title">大家都在看：</div>
      <div class="recommend recommend_standard">
        <div class="content">
          <ul>
            <li v-for="item in list" :key="item">
              <router-link :to="{name: 'standarddetail', params: { id: item.id, classId: item.secondIndustry}}" v-html="item.allName">
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="recommend-title">相关产品：</div>
      <div class="recommend recommend_standard_product">
        <div class="content">
          <list
                  url="/M/Product/getProductByStandardId"
                  :params="{ standardId: standard.id}"
                  :filterFunc="productMap"
                  :show="true"
                  emptyMsg="整理中..."
          >
            <template v-slot:default="data">
              <product-card :list="data" name="productdetail" tid="0"></product-card>
            </template>
          </list>
        </div>
      </div>
<!--
      <div class="recommend-title">标物推荐：</div>
      <div class="recommend">
        <div class="content">
          <ul>
            <template v-if="proList.length > 0">
              <li v-for="item in proList" :key="item">
                <router-link :to="{name: 'productdetail', params: { id: item.id }}">
                {{ item.cnname }}
                </router-link>
              </li>
            </template>
          </ul>
        </div>
      </div>
-->
    </div>
    <!--使用tid传递路劲-->
    <slot-footer
      :pid="id"
      :tid="filePath"
      nclassId="0"
      type="2"
      :isFav="isFav"
      :buttonType="2"
      viewCartText="下载"
    ></slot-footer>
  </div>
  <loading :options="loadOption" v-else></loading>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, inject, watch } from "vue";
import { useRoute } from "vue-router";
import api from "@/api/axios";

import SlotFooter from "@/components/common/SlotFooter.vue";
import Loading from "@/components/UI/Loading";
import list from "@/components/common/list.vue";
import productCard from "@/components/common/productCard.vue";
import { productMap } from "@/hooks/useProduct";


export default defineComponent({
  name: "standarddetail",
  components: {
    SlotFooter,
    Loading,
    list,
    productCard
  },
  setup() {
    const route = useRoute();
    const id = ref(route.params.id);
    const standard = ref(null);
    const list = ref([]);
    const proList = ref([]);
    const isFav = ref(false);
    const filePath = ref();

    function getData(id: any) {
      standard.value = null;
      api
        .get("/M/Product/StandardDetail/" + id)
        .then((res): void => {
          const data = res.data.obj;
          standard.value = data.standard;
          isFav.value = data.isFav;
          filePath.value = data.standard.filePath;
          // console.log("filePath",filePath.value)
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getData(id.value)
    function getNext(){
       api
        .get("/M/Product/StandardDetailRelation")
        .then((res): void => {
          const data = res.data.obj;
          list.value = data.list;
          proList.value = data.proList;
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    getNext();

    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.params;
        },
      ],
      (to, from) => {
        // 避免登录时触发页面刷新
        if (to[1].id && !from[1].valid) {
          getData(to[1].id as string);
          getNext();
        }
      }
    );
    return {
      standard,
      list,
      proList,
      id,
      loadOption,
      productMap,
      isFav,
      filePath
    };
  },
});
</script>
<style lang="scss">
  .recommend_standard_product .module {
    margin-bottom: 0px !important;
  }
  .recommend_standard_product .empty {
    padding-top: 30px !important;
    padding-bottom: 0px !important;
  }
</style>
<!-- scoped:样式穿透，写在里面的样式会修改公用组件的样式，作用域全局-->
<style lang="scss" scoped>
.standard-wrap {
  padding-bottom: 50px;
}
.product-pic img {
    width: 375px;
    height: 375px;
    display: block;
  }

  .product-info {
    position: relative;
    padding: 10.013px;
    padding-top: 21.487px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 6px solid #f4f4f4;
  }

  .product-name {
    width: 100%;
    /*width: 255px;*/
    color: #444444;
    font-size: 16.012px;
    position: relative;
    font-weight: bold;
  }

  .operation {
    position: absolute;
    right: 10.013px;
    top: 50%;
    transform: translateY(-50%);
  }
  .operation a {
    color: #cc3333;
    font-size: 15px;
    font-weight: 700;
  }

  .product-detail .title {
    padding-left: 19.988px;
    font-size: 15px;
    color: #444;
    position: relative;
    width: 375px;
    box-sizing: border-box;
    height: 43.988px;
    line-height: 43.988px;
    border-bottom: 1px solid #f4f4f4;
  }
  .product-detail .title::after {
    content: "";
    position: absolute;
    width: 2px;
    left: 10.013px;
    top: 50%;
    height: 13.012px;
    background-color: #df0024;
    margin-top: -6.487px;
  }

  table {
    width: 355.013px;
    border: none;
    margin: 0 auto;
    margin-top: 19.988px;
    border-collapse: collapse;
    border-color: #ccc;
  }
  thead {
    width: 355.013px;
    background-color: #df0024;
    border: 1px solid #df0024;
  }
  th {
    color: #fff;
    text-align: left;
    height: 30px;
    box-sizing: border-box;
    padding: 0 9px;
    line-height: 30px;
    font-size: 13.988px;
  }
  td {
    font-size: 13.988px;
    color: #666666;
    padding: 10.013px;
    word-break: break-word;
  }

  .name {
    width: 117px;
    box-sizing: border-box;
    border-right-color: #e5e5e5;
    background-color: #f2f2f2;
    line-height: 25.013px;
  }
  .detail-name {
    width: 49.987px;
    box-sizing: border-box;
  }
  .other-view {
    margin-top: 19.988px;
    border-top: 1px solid #eee;
  }
  .other-view .title {
    text-align: justify;
    font-size: 16.012px;
    color: #666666;
    line-height: 25.013px;
    margin: 19.988px 10.013px;
    font-weight: bold;
  }

  .other-view .content .detail-title {
    width: 355.013px;
    margin: 0 auto;
    font-size: 16.012px;
    color: #444;
    position: relative;

    margin-top: 19.988px;
    box-sizing: border-box;
  }

  .other-view .content .detail-content {
    width: 355.013px;
    margin: 0 auto;
    font-size: 15px;
    color: #444;
    position: relative;

    box-sizing: border-box;
  }

  .pdt_detail_box {
    font-size: 16.012px;

    color: #444;
    width: 375px;
    box-sizing: border-box;
    width: 355.013px;
    margin: 0 auto;
  }

  .info-left {
    margin: 0 auto;
    text-align: center;
  }

  .productViews {
    width: 109.987px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background-color: #df0024;
    color: #fff;
    border-radius: 36px;
    margin: 0 auto;
    margin-top: 7.987px;
  }

  .other-view .content a {
    text-align: justify;
    font-size: 16.012px;
    line-height: 25.013px;
    display: block;
    color: #666666 !important;
    margin: 12.488px 10.013px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .recommend{
    width: 355.013px;
    text-align: left;
    margin: 0 auto;

    box-sizing: border-box;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
    position: relative;
}

.recommend .title{
    padding-left: 0.53301rem;
    font-size: 0.4rem;
    color: #444;
    position: relative;
    box-sizing: border-box;
    height: 1.17301rem;
    line-height: 1.17301rem;
    border-bottom: 0.02667rem solid #f4f4f4;
    margin-top: 0.5rem;

}
.recommend .title::after{
    content: "";
    position: absolute;
    width: 0.05333rem;
    left: 0.26701rem;
    top: 50%;
    height: 0.34699rem;
    background-color: #df0024;
    margin-top: -0.17299rem;
}
.recommend li{
   position: relative;
}

.recommend li::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 10.013px;
    width: 4.988px;
    height:4.988px;
    background: #7f7f7f;
    border-radius: 50%;
}

.recommend a{
    text-align: justify;
    font-size: 13.988px;
    line-height: 25.013px;
    display: block;
    color: #666666;
    margin: 10.013px 0;
    overflow: hidden;
    padding-left: 10.013px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.recommend_standard{
  border-bottom: 0px;
  padding-bottom: 0;
}
.recommend_standard a{
  border-bottom: 0px;
}
.recommend h3{
    margin: 0;
    font-weight: normal;
}

.recommend-title {
    padding-left: 19.988px;
    font-size: 15px;
    color: #444;
    position: relative;
    width: 375px;
    box-sizing: border-box;
    height: 43.988px;
    line-height: 43.988px;
    border-bottom: 1px solid #f4f4f4;
  }

  .recommend-title{
    margin-top: 0.5rem;;
  }
  .recommend-title::after {
    content: "";
    position: absolute;
    width: 2px;
    left: 10.013px;
    top: 50%;
    height: 13.012px;
    background-color: #df0024;
    margin-top: -6.487px;
  }
</style>
