
import { defineComponent, ref, reactive, inject, watch } from "vue";
import { useRoute } from "vue-router";
import api from "@/api/axios";

import SlotFooter from "@/components/common/SlotFooter.vue";
import Loading from "@/components/UI/Loading";
import list from "@/components/common/list.vue";
import productCard from "@/components/common/productCard.vue";
import { productMap } from "@/hooks/useProduct";


export default defineComponent({
  name: "standarddetail",
  components: {
    SlotFooter,
    Loading,
    list,
    productCard
  },
  setup() {
    const route = useRoute();
    const id = ref(route.params.id);
    const standard = ref(null);
    const list = ref([]);
    const proList = ref([]);
    const isFav = ref(false);
    const filePath = ref();

    function getData(id: any) {
      standard.value = null;
      api
        .get("/M/Product/StandardDetail/" + id)
        .then((res): void => {
          const data = res.data.obj;
          standard.value = data.standard;
          isFav.value = data.isFav;
          filePath.value = data.standard.filePath;
          // console.log("filePath",filePath.value)
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getData(id.value)
    function getNext(){
       api
        .get("/M/Product/StandardDetailRelation")
        .then((res): void => {
          const data = res.data.obj;
          list.value = data.list;
          proList.value = data.proList;
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    getNext();

    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.params;
        },
      ],
      (to, from) => {
        // 避免登录时触发页面刷新
        if (to[1].id && !from[1].valid) {
          getData(to[1].id as string);
          getNext();
        }
      }
    );
    return {
      standard,
      list,
      proList,
      id,
      loadOption,
      productMap,
      isFav,
      filePath
    };
  },
});
